// import { firebase } from "@firebase/app";
// import "@firebase/auth";
// import "@firebase/firestore";

// const firebaseApp = firebase.initializeApp({
//   apiKey: "AIzaSyC8VTYAq_ODZVqT8gIXAIpQrOFMLuRollA",
//   authDomain: "vue-recipes-ed4ca.firebaseapp.com",
//   databaseURL: "https://vue-recipes-ed4ca.firebaseio.com",
//   projectId: "vue-recipes-ed4ca",
//   storageBucket: "vue-recipes-ed4ca.appspot.com",
//   messagingSenderId: "932956321577",
//   appId: "1:932956321577:web:156272c8e079799e"
// });

// export const db = firebaseApp.firestore();
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
  apiKey: "AIzaSyC8VTYAq_ODZVqT8gIXAIpQrOFMLuRollA",
  authDomain: "vue-recipes-ed4ca.firebaseapp.com",
  databaseURL: "https://vue-recipes-ed4ca.firebaseio.com",
  projectId: "vue-recipes-ed4ca",
  storageBucket: "vue-recipes-ed4ca.appspot.com",
  messagingSenderId: "932956321577",
  appId: "1:932956321577:web:156272c8e079799e"
};
// eslint-disable-next-line
const firestore = firebase.initializeApp(config);
export const db = firebase.firestore();

export default firebase;