<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column is-two-thirds">
          <div class="field">
            <label class="label">Recipe Name</label>
            <div class="control">
              <input maxlength="86" class="input" type="text" placeholder="Recipe Name" v-model="recipeName" v-bind:class="[recipeName ? 'is-normal' : 'is-danger']">
              <p v-show="!recipeName" class="help is-danger">
                This field is required
              </p>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">Prep Time</label>
            <div class="control">
              <input min="0" max="1024" class="input" type="number" placeholder="Prep Time" v-model="prepTime">
            </div>
          </div>
        </div>

        <div class="column">
          <div class="field">
            <label class="label">Cook Time</label>
            <div class="control">
              <input min="0" max="1024" class="input" type="number" placeholder="Cook Time" v-model="cookTime">
            </div>
          </div>
        </div>

        <div class="column">
          <div class="field">
            <label class="label">Servings</label>
            <div class="control">
              <input min="0" max="1024" class="input" type="number" placeholder="Servings" v-model="servings">
            </div>
          </div>
        </div>

      </div>

      <div class="field">
        <label class="label">Ingredients</label>
        <div class="control">
          <textarea maxlength="4096" class="textarea" placeholder="Ingredients" v-model="ingredients" required></textarea>
        </div>
      </div>

      <div class="field">
        <label class="label">Directions</label>
        <div class="control">
          <textarea maxlength="4096" class="textarea" placeholder="Directions" v-model="directions"></textarea>
        </div>
      </div>

      <div class="field">
        <label class="label">Notes</label>
        <div class="control">
          <textarea maxlength="4096" class="textarea" placeholder="Notes" v-model="notes"></textarea>
        </div>
      </div>

      <div class="field">
        <label class="label">Original Recipe</label>
        <div class="control">
          <input maxlength="256" class="input" type="text" placeholder="Original Recipe" v-model="original">
        </div>
      </div>

      <div class="control">
        <!-- <button class="button is-primary" @click.stop.prevent="addRecipe">Submit</button> -->
        <button class="button" v-bind:class="[recipeName ? 'is-success' : 'is-dark']" v-on="recipeName ? { click: addRecipe } : {}">Add Recipe</button>
      </div>
    </div>

  </section>
</template>

<script>
  import { db } from '../firebase';

  export default {
    data() {
      return {
        recipeName: '',
        prepTime: '',
        cookTime: '',
        servings: '',
        ingredients: [],
        directions: [],
        notes: '',
        original: ''
      }
    },
    firestore() {
      return {
        recipes: db.collection('recipes')
      }
    },
    methods: {
      addRecipe: function () {
        if (this.ingredients.length) {
          this.ingredients = this.ingredients.split(/\r?\n/);
        } else {
          this.ingredients.push(this.recipeName)
        }
        if (this.directions.length) {
          this.directions = this.directions.split(/\r?\n/);
        } else {
          this.directions.push('Eat and enjoy!')
        }
        this.$firestore.recipes.add({
          name: this.recipeName,
          prepTime: parseInt(this.prepTime),
          cookTime: parseInt(this.cookTime),
          servings: parseInt(this.servings),
          ingredients: this.ingredients,
          directions: this.directions,
          notes: this.notes,
          original: this.original,
          createdBy: this.$store.getters.getUser.email,
          timestamp: new Date()
        })
        this.$router.push('/recipes');
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../sass/mystyles.scss';

  .label {
    color: $my-white;
    font-weight: normal;
  }
</style>