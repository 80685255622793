<template>
  <section class="section">
    <div class="container">
      <h1>user information</h1>
      <br />
      <p>display name: <span>{{ display }}</span></p>
      <p>email: <span>{{ email }}</span></p>
      <p>uid: <span>{{ uid }}</span></p>
    </div>
  </section>
</template>

<script>
  export default {
    data() {
      return {

      }
    },
    computed: {
      email: function () {
        return this.$store.getters.getUser.email
      },
      display: function () {
        return this.$store.getters.getUser.displayName
      },
      uid: function () {
        return this.$store.getters.getUser.uid
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../sass/mystyles.scss';

  h1 {
    color: $my-white;
  }
  p {
    color: $my-yellow;

    & span {
      color: $my-white;
    }
  }
</style>