import Vue from 'vue';
import App from './App.vue';
import VueFirestore from 'vue-firestore';
import VueRouter from 'vue-router';
import Routes from './routes';
import { store } from "./store";

import firebase from "./firebase";

import '../public/css/styles.css'

// vuejs-dialog package
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
Vue.use(VuejsDialog);
//

Vue.config.productionTip = false

Vue.use(VueFirestore);
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: Routes
})

router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  document.title = 'My Recipe App'
  if (requiresAuth && !currentUser) next("/");
  else if (!requiresAuth && currentUser) next();
  else next();
});

let app = "";
// eslint-disable-next-line
firebase.auth().onAuthStateChanged(user => {
  if (!app) {
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount("#app");
  }
});