<template>
  <section class="section">
    <div class="container recipe-header">
      <div class="columns level">
        <div class="column is-two-thirds">
          <div class="heading">
            <h1 v-if="!isEditing" class="title recipe-header__title">
              {{ recipe.name }}
            </h1>
            <div class="control" v-if="isEditing">
              <input
                maxlength="86"
                class="input"
                type="text"
                v-model="recipe.name"
                v-bind:class="[recipe.name ? 'is-normal' : 'is-danger']"
              />
              <p v-show="!recipe.name" class="help is-danger">
                This field is required
              </p>
            </div>
          </div>
        </div>
        <div class="column has-text-right no-print">
          <span
            class="tag is-info recipe-header__tag"
            v-show="!isEditing"
            @click="edit"
            >edit</span
          >
          <span
            class="tag is-danger recipe-header__tag"
            v-show="!isEditing"
            @click="del"
            >delete</span
          >
          <span class="tag is-success recipe-header__tag" v-show="!isEditing"
            >share</span
          >
          <span
            class="tag is-danger recipe-header__tag"
            v-show="isEditing"
            @click="cancel"
            >cancel</span
          >
          <span
            class="tag recipe-header__tag"
            v-bind:class="[recipe.name ? 'is-success' : 'is-dark']"
            v-show="isEditing"
            v-on="recipe.name ? { click: save } : {}"
            >save</span
          >
        </div>
      </div>
    </div>

    <div class="container recipe-meta">
      <div class="columns">
        <div class="column is-one-fifth recipe-meta__col">
          <span class="recipe-meta__title">prep time: </span>
          <span class="recipe-meta__data" v-if="!isEditing">{{
            parseTime(recipe.prepTime)
          }}</span>
          <span class="control" v-if="isEditing">
            <input
              min="0"
              max="1024"
              class="input is-small"
              type="number"
              v-model="recipe.prepTime"
            />
          </span>
        </div>
        <div class="column is-one-fifth recipe-meta__col">
          <span class="recipe-meta__title">cook time: </span>
          <span class="recipe-meta__data" v-if="!isEditing">{{
            parseTime(recipe.cookTime)
          }}</span>
          <span class="control" v-if="isEditing">
            <input
              min="0"
              max="1024"
              class="input is-small"
              type="number"
              v-model="recipe.cookTime"
            />
          </span>
        </div>
        <div class="column is-one-fifth recipe-meta__col">
          <span class="recipe-meta__title">total time: </span>
          <span class="recipe-meta__data">{{
            parseTime(
              calcTime(parseInt(recipe.cookTime), parseInt(recipe.prepTime))
            )
          }}</span>
        </div>
      </div>
      <div class="columns">
        <div class="column is-one-fifth recipe-meta__col">
          <span class="recipe-meta__title">servings: </span>
          <span class="recipe-meta__data" v-if="!isEditing">{{
            recipe.servings
          }}</span>
          <span class="control" v-if="isEditing">
            <input
              min="0"
              max="1024"
              class="input is-small"
              type="number"
              v-model="recipe.servings"
            />
          </span>
        </div>
        <div class="column is-one-fifth recipe-meta__col">
          <span class="recipe-meta__title">calories per serving: </span
          ><span class="recipe-meta__data"></span>
        </div>
        <div class="column is-one-fifth recipe-meta__col">
          <span class="recipe-meta__title">nutritional info </span
          ><span class="recipe-meta__data"
            ><i class="fas fa-info-circle"></i
          ></span>
        </div>
      </div>
    </div>

    <div class="container recipe-detail">
      <div class="columns">
        <div class="column is-one-third recipe-detail--ingredients">
          <div class="subtitle recipe-detail--subtitle">ingredients</div>
          <ul v-if="!isEditing">
            <li v-for="(ingredient, idx) in recipe.ingredients" :key="idx">
              <span v-if="ingredient.startsWith('@')">
                <br />
                <span
                  class="recipe-detail--ingredients__section is-size-6 has-text-info"
                  >{{ ingredient.substr(1) }}</span
                >
              </span>
              <span v-else>{{ ingredient }}</span>
            </li>
          </ul>
          <ul v-if="isEditing">
            <li
              v-for="(ingredient, idx) in recipe.ingredients"
              :key="idx"
              class="recipe-detail--ingredients__input"
            >
              <div class="field has-addons level">
                <i
                  class="fas fa-arrow-circle-up arrow has-text-primary"
                  @click="moveUp(recipe.ingredients, ingredient)"
                ></i>
                <i
                  class="fas fa-arrow-circle-down arrow has-text-warning"
                  @click="moveDown(recipe.ingredients, ingredient)"
                ></i>
                <input
                  maxlength="1024"
                  class="input is-small"
                  v-model="recipe.ingredients[idx]"
                />
                <p class="control">
                  <i
                    class="far fa-trash-alt trash-can has-text-danger"
                    @click="delArrItem(recipe.ingredients, idx)"
                  ></i>
                </p>
              </div>
            </li>
          </ul>
          <div class="addRow">
            <i
              class="fas fa-plus-circle has-text-success"
              v-if="isEditing"
              @click="addArrItem(recipe.ingredients)"
            ></i>
          </div>
        </div>
        <div class="column is-two-thirds recipe-detail--directions">
          <div class="subtitle recipe-detail--subtitle">directions</div>
          <ul v-if="!isEditing">
            <li v-for="(direction, idx) in recipe.directions" :key="idx">
              <span v-if="direction.startsWith('@')">
                <br />
                <span
                  class="recipe-detail--directions__section is-size-6 has-text-info"
                  >{{ direction.substr(1) }}</span
                >
              </span>
              <span v-else>{{ direction }}</span>
            </li>
          </ul>
          <ul v-if="isEditing">
            <li
              v-for="(direction, idx) in recipe.directions"
              :key="idx"
              class="recipe-detail--ingredients__input"
            >
              <div class="field has-addons">
                <i
                  class="fas fa-arrow-circle-up arrow has-text-primary"
                  @click="moveUp(recipe.directions, direction)"
                ></i>
                <i
                  class="fas fa-arrow-circle-down arrow has-text-warning"
                  @click="moveDown(recipe.directions, direction)"
                ></i>
                <input
                  maxlength="1024"
                  class="input is-small"
                  v-model="recipe.directions[idx]"
                />
                <p class="control">
                  <i
                    class="far fa-trash-alt trash-can has-text-danger"
                    @click="delArrItem(recipe.directions, idx)"
                  ></i>
                </p>
              </div>
            </li>
          </ul>
          <div class="addRow">
            <i
              class="fas fa-plus-circle has-text-success"
              v-if="isEditing"
              @click="addArrItem(recipe.directions)"
            ></i>
          </div>
        </div>
      </div>
    </div>

    <div class="container recipe-notes">
      <div class="columns">
        <div class="column">
          <div class="subtitle recipe-notes--subtitle">
            notes
          </div>
          <div v-if="!isEditing" class="recipe-notes--notes">
            <p>{{ recipe.notes }}</p>
          </div>
          <div v-if="isEditing">
            <div class="control">
              <textarea
                maxlength="4096"
                class="textarea"
                v-model="recipe.notes"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container recipe-notes">
      <div class="columns">
        <div class="column">
          <div class="subtitle recipe-notes--subtitle">
            original recipe
          </div>
          <div v-if="!isEditing" class="recipe-notes--original">
            <a v-bind:href="recipe.original" target="_blank">{{
              recipe.original
            }}</a>
          </div>
          <div class="control" v-if="isEditing">
            <input
              maxlength="256"
              class="input"
              type="text"
              v-model="recipe.original"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { db } from '../firebase';
import { cloneDeep } from 'lodash';
export default {
  data() {
    return {
      errorMessage: '',
      isEditing: false,
      hasChanged: false,
    };
  },
  beforeCreate() {
    if (!this.$options.propsData.recipe) {
      this.$router.replace('/recipes');
    }
  },
  props: ['recipe'],
  firestore() {
    return {
      recipes: db.collection('recipes'),
    };
  },
  computed: {
    trackChanges() {
      return this.recipe;
    },
  },
  watch: {
    trackChanges: {
      handler() {
        this.hasChanged = true;
      },
      deep: true,
    },
  },
  methods: {
    parseTime: function(time) {
      let h = Math.trunc(time / 60);
      let m = Math.trunc(time % 60);
      h > 0 ? (h = h + 'h') : (h = '');
      m > 0 ? (m = m + 'm') : (m = '');
      if (h == '' && m == '') return (time = 'n/a');
      return (time = h + ' ' + m);
    },
    calcTime: function(...times) {
      return times.reduce((previous, current) => {
        return previous + current;
      });
    },
    addArrItem: function(arr) {
      arr.push('');
    },
    delArrItem: function(arr, idx) {
      arr.splice(idx, 1);
    },
    move: function(array, element, delta) {
      var index = array.indexOf(element);
      var newIndex = index + delta;
      if (newIndex < 0 || newIndex == array.length) return; //Already at the top or bottom.
      // var indexes = [index, newIndex].sort(index - newIndex); //Sort the indixes
      var indexes = [index, newIndex].sort((a, b) => a - b);
      array.splice(indexes[0], 2, array[indexes[1]], array[indexes[0]]); //Replace from lowest index, two elements, reverting the order
    },
    moveUp: function(array, element) {
      this.move(array, element, -1);
    },
    moveDown: function(array, element) {
      this.move(array, element, +1);
    },
    del: function() {
      let message =
        'Are you sure you want to delete this recipe? This cannot be undone!';
      let options = {
        okText: 'Delete',
        cancelText: 'Cancel',
      };
      this.$dialog
        .confirm(message, options)
        .then(() => {
          this.$firestore.recipes.doc(this.recipe['.key']).delete();
          this.$router.push('/recipes');
        })
        .catch(err => {
          this.errorMessage = err.message;
        });
    },
    edit: function() {
      this.beforeEditingCache = cloneDeep(this.recipe);
      this.isEditing = true;
    },
    save: function() {
      if (!this.hasChanged) {
        this.isEditing = false;
      } else {
        let ints = ['prepTime', 'cookTime', 'servings'];
        ints.forEach(el => {
          this.recipe[el] < 0
            ? (this.recipe[el] = 0)
            : (this.recipe[el] = parseInt(this.recipe[el]));
        });
        this.$firestore.recipes.doc(this.recipe['.key']).set(this.recipe);
        this.hasChanged = false;
        this.isEditing = false;
      }
    },
    cancel: function() {
      this.isEditing = false;
      delete this.beforeEditingCache.id;
      Object.assign(this.recipe, this.beforeEditingCache);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../sass/mystyles.scss';
i {
  font-size: 1rem;

  &.trash-can {
    padding-left: 0.5rem;
  }

  &.arrow {
    padding-right: 0.5rem;
  }
}

// Override bulma breadcrumb
.recipe-header {
  border-bottom: 1px dashed $my-grey-light;
  padding-bottom: 1rem;

  & h1.recipe-header__title {
    color: $my-grey-light;
    font-weight: 300;
    font-size: 2rem;
    text-transform: lowercase;
  }

  & .recipe-header__tag {
    margin-right: 1rem;

    &:hover {
      cursor: pointer;
    }
  }
}

.recipe-meta {
  margin-top: 1.5rem;

  color: $my-white;
  font-size: 0.75rem;
  text-transform: lowercase;

  &__col {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    overflow: hidden;
  }

  &__title {
    font-weight: 300;
  }

  &__data {
    font-weight: 400;
  }
}

.recipe-detail {
  margin-top: 1.5rem;

  font-size: 0.75rem;
  text-transform: lowercase;

  &--subtitle {
    color: $my-grey-light;
  }

  &--ingredients,
  &--directions {
    color: $my-white;
    font-weight: 400;
    word-break: break-word;

    &__input {
      padding-bottom: 0.5rem;
    }

    &__section {
      font-weight: 500;
    }
  }

  &--directions {
    ul {
      list-style-position: inside;
    }

    li {
      padding-bottom: 0.5rem;
    }
  }
}

.recipe-notes {
  margin-top: 1.5rem;

  font-size: 0.75rem;
  text-transform: lowercase;

  &--subtitle {
    color: $my-grey-light;
  }

  &--notes {
    font-weight: 400;
    color: $my-white;
  }

  &--original {
    a {
      color: $my-white;

      &:hover {
        color: $my-yellow;
      }
    }
  }
}

@media print {
  .recipe-meta,
  .recipe-detail--ingredients,
  .recipe-detail--directions,
  .recipe-notes--notes,
  .recipe-notes--original a {
    color: $my-black;
  }
  .no-print {
    display: none;
  }
}
</style>
