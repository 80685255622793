<template>
  <div class="container">
    <p class="has-text-danger has-text-centered">Sorry, we are not accepting signups at the moment.</p>
  </div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>
.container {
  padding-top: 10vh;
}
</style>

