import Home from './components/Home.vue'
import RecipeList from './components/RecipeList.vue'
import RecipeDetail from './components/RecipeDetail.vue'
import AddRecipe from './components/AddRecipe.vue'
import Signup from './components/Signup.vue'
import Profile from './components/Profile.vue'

export default [{
    path: '/',
    component: Home
  },
  {
    name: 'Profile',
    path: '/profile',
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'Signup',
    path: '/signup',
    component: Signup
  },
  {
    name: 'AddRecipe',
    path: '/add',
    component: AddRecipe,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'RecipeList',
    path: '/recipes',
    component: RecipeList,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'RecipeDetail',
    path: '/recipedetail',
    component: RecipeDetail,
    props: true,
    meta: {
      requiresAuth: true
    }
  }
]