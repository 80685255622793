<template>
  <div class="container">
    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <router-link to="/">
          <a class="navbar-item">my recipes</a>
        </router-link>

        <a
          role="button"
          class="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          @click="isOpen = !isOpen"
          v-bind:class="{ 'is-active': isOpen }"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div class="navbar-menu" v-bind:class="{ 'is-active': isOpen }">
        <div class="navbar-end">
          <span v-if="isLoggedIn">
            <router-link to="recipes">
              <a class="navbar-item">recipe list</a>
            </router-link>
          </span>
          <span v-if="isLoggedIn">
            <router-link to="add">
              <a class="navbar-item">add recipe</a>
            </router-link>
            <!-- <a href="/recipedetail" class="navbar-item">detail</a> -->
          </span>
          <span v-if="isLoggedIn">
            <router-link to="profile">
              <a class="navbar-item">my profile</a>
            </router-link>
            <!-- <a href="/recipedetail" class="navbar-item">detail</a> -->
          </span>

          <div class="navbar-item">
            <span v-if="!isLoggedIn">
              <div class="buttons are-small">
                <router-link to="/signup">
                  <a class="button is-primary">Sign up</a>
                </router-link>
              </div>
            </span>
          </div>
          <div class="navbar-item">
            <span v-if="isLoggedIn">
              <div class="buttons are-small">
                <a class="button is-danger" @click.prevent.stop="logout"
                  >Logout</a
                >
              </div>
            </span>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import firebase from '../firebase';

export default {
  name: 'Header',
  data: function() {
    return {
      isOpen: false,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace('/');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../sass/mystyles.scss';

.navbar {
  border-bottom: 1px solid $my-white;

  &-burger {
    &:hover {
      color: $my-yellow;
    }
  }

  & .navbar-item {
    & .buttons {
      margin-bottom: 0;
    }
  }
}
</style>
