<template>
  <section class="section">
    <div class="container">
      <p class="has-text-danger" v-if="isError">Oh fork, something went wrong! Don't worry, we have been notified.</p>
      <div class="columns is-multiline">
        <div class="column is-half has-text-centered" v-for="recipe in recipes" :key="recipe.id">
          <a @click="getRecipe(recipe)">{{ recipe.name }}</a>
          <!-- <div class="recipe-tags">tags: [tag1] [tagtwo] [tagthree] [fourthtag]</div> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { db } from '../firebase';

  export default {
    data() {
      return {
        recipes: [],
        recipe: {},
        isError: ''
      }
    },
    // firestore() {
    //   return {
    //     recipes: db.collection('recipes')
    //   }
    // },
    firestore() {
      return {
        recipes: {
          ref: db.collection('recipes'),
          resolve: (data) => {
            return data.sort((a, b) => (a.name > b.name) ? 1 : -1)
          },
          reject: (err) => {
            return this.isError = err
          }
        }
      }
    },
    methods: {
      getRecipe(recipe) {
        this.$router.push({
          name: 'RecipeDetail',
          params: {
            recipe
          }
          
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../sass/mystyles.scss';
  a {
    text-transform: lowercase;

    &:hover {
      color: $my-white;
    }
  }
  .column {
    padding-bottom: 0;
    overflow: hidden;
  }

  .recipe-tags {
    font-size: .75rem;
    color: $my-white;
  }

  .recipe-delete {
    background-color: hsl(348, 100%, 61%);
    margin-left: 0.5rem;

    &:hover {
      cursor: pointer;
      background-color: hsl(348, 100%, 50%);
    }

  }
</style>