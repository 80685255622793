<template>
  <div class="container login-box" v-if="!isLoggedIn">
    <h1 class="title has-text-centered">my recipes login</h1>
    <form @submit.prevent="login">
      <div class="field">
        <label class="label">email address</label>
        <div class="control">
          <input
            class="input is-info"
            type="email"
            placeholder="email"
            v-model="email"
          />
        </div>
      </div>
      <div class="field">
        <label class="label">password</label>
        <div class="control">
          <input
            class="input is-info"
            type="password"
            placeholder="password"
            v-model="password"
          />
        </div>
      </div>
      <div class="field">
        <div class="control has-text-centered">
          <button class="button is-info">
            Login
          </button>
        </div>
      </div>
    </form>
    <div class="login-message has-text-centered">
      <p v-if="errorMessage" class="has-text-danger">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import firebase from '../firebase';

export default {
  data() {
    return {
      email: '',
      password: '',
      successMessage: '',
      errorMessage: '',
    };
  },
  created() {
    if (this.$store.getters.getUser) {
      this.$router.replace('/recipes');
    }
  },
  computed: {
    isLoggedIn() {
      return firebase.auth().currentUser;
    },
  },
  methods: {
    login() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        // eslint-disable-next-line
        .then(user => {
          this.$router.push('/recipes');
        })
        .catch(err => {
          this.errorMessage = err.message;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../sass/mystyles.scss';

.login-box {
  width: 25rem;
  padding: 3rem 2rem;

  & .title {
    color: $my-white;
  }

  & label {
    color: $my-white;
    font-weight: normal;
  }

  & .login-message {
    margin-top: 2rem;
  }
}
</style>
